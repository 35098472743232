.user-list-header{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.user-list-header button{
    width: 30px;
}

.user-list-header h5{
    color: red;
    font-size: 16px;
    font-weight: bold;
}


.pagination{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    border-bottom: 2px solid #eeeeee;
    padding: 5px 10px;
    background-color: white;
    justify-items: center;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.pagination button{
    border:none;
    background-color: transparent;
}
.pagination .next{
    transform: rotate(-90deg);
    display: inline-block;
}

.pagination .prev{
    transform: rotate(90deg);
    display: inline-block;
}
.pagination .next.active,.pagination .prev.active{
    color: deeppink;
}

.pagination .indexes{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.pagination .index{
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 5px;
    margin: 0 5px;
}
.pagination .index.active{
    background-color: hotpink;
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.datatable{
    background-color: white;
    border-radius: 10px;
    font-size: 14px;
    border: 1px solid #eeeeee;
    width: 100%;
    min-height: 70vh;
    text-align: right;
}

.datatable .datatable-header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-items: center;
    justify-content: right;
    padding: 10px;
    color: red;

}
.datatable .datatable-header .btn{
    width: 25px;
    height: 25px;
    text-align: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: red;
    color: white;
    display: flex;
}
.datatable .datatable-header span{
    margin-left: 10px;
    text-align: center;
    width: 100%;
    padding: 0;
    font-weight: bold;

}.datatable .datatable-header h5{
    font-weight: bold;
    font-size: 16px;
     justify-content: center;
     align-items: center;
     justify-items: center;
    padding: 0;
    line-height: 30px;

}

.datatable-filterbox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: white;
    padding: 10px 5px;
    border-radius: 10px;
    font-size: 14px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-items: center;
    text-align: right;
}


.datatable-filterbox .title{
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    display: block;
}
.datatable-filterbox .input{
    flex: 4;
}
.datatable-filterbox .input input{

    background-color: #eee;
}
.datatable-filterbox .button{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.datatable-filterbox .mini-button{
    border-radius: 8px;
    background-color: blue;
    color: white;
    width: 25px;
    height: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.datatable-filterbox-accounting {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 10px;
    font-size: 14px;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-items: center;
    text-align: right;
    padding: 20px;
    padding-bottom: 0;
}

.datatable-filterbox-accounting .title {
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    display: block;
}

.datatable-filterbox-accounting .input {
    flex: 4;
}

.datatable-filterbox-accounting .input input {

    background:#fbfbfb;color:#000
}
.css-11n5pbm-control {
    background:#fbfbfb !important;color:#000
}
input.silver, select.silver, textarea.silver {
    background-color:#fbfbfb;color:#000}

.datatable-filterbox-accounting .button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.datatable-filterbox-accounting .filter-checks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.row {
    width: 100%;
}
