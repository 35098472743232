body{
    min-height: 100vh;
    box-sizing: border-box;
}
#login{

    width: 100%;
    text-align: right;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    direction: rtl;
    border: 1px solid #e5e5e5;box-sizing: border-box;
}


/*.login-banner{
    background-color: #b4a7f9;
    background-image: url("./login-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 70%;
    height: 100vh;
}*/
#login{
    background-color: #b4a7f9;
    background-image: url("./login-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
}

#login .login-section{
    height: 100vh;
    width: 30%;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    align-items: center;align-self: center;
}

#login .login-copyright{
    direction: ltr;
    font-size: 12px;
    width: 100%;
    text-align: center;
}

#login .login-copyright span{
    display: block;
}

#login .login-copyright a{
    color: mediumseagreen;
    margin: 0 5px;
}

.inner-addon {
    position: relative;
}

/* style glyph */
.inner-addon .icon {
    position: absolute;
    padding: 10px;
    pointer-events: none;
    color: black;
}
.login-form.inner-addon .icon{color: #7d1fcc;}

/* align glyph */
.left-addon .icon  { left:  0px;}
.right-addon .icon { right: 0px;}

/* add padding  */
.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 40px;     border: 1px solid #d7d7d7 !important;}

#root{
    min-height: 100vh;
}

input[type=checkbox], input[type=radio] {

    margin-left: 5px;
}

@media (max-width: 992px){
    #login{
       position: relative;
        width: 100%;
        min-height: 100vh;
    }


    .login-banner{
        width: 100%;
        height: 100%
    }

    #login .login-section{
     /*   position: absolute;*/
        height: auto;
        width: 90%;
        top: 10px;
        bottom: 10px;
        margin: auto;
        left: 0;
        right: 0;
        border-radius: 8px;
        background-color: #f7f7f7;
    }

    #login .login-copyright{
        direction: ltr;
        font-size: 12px;
        width: 100%;
        text-align: center;
    }

    #login .login-copyright span{
        display: block;
    }

    #login .login-copyright a{
        color: mediumseagreen;
        margin: 0 5px;
    }
}
@media (max-width:480px){
    @media (max-width:480px){
        #login{display: block}
    }
    #login .login-section{border-radius: 0 0 8px 8px}
}

