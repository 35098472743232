/*right-menu*/
#side-menu{
    background-color: #6c58ce;
    text-align: right;
    min-height: 400px;
    border-radius: 8px;
    border: 1px solid #eeeeee;
}

#side-menu .menu-items{
    display: flex;
    flex-direction: column;
    padding-top: 2px;
    padding-right: 5px;
    padding-left: -5px;
}

#side-menu .menu-items .menu-item{
 /*   display: flex;*/
    width: 100%;
   /* flex-direction: row;
    flex-wrap: nowrap;*/
    color: white;
    padding:2px 0;
    font-size: 14px;
}
#side-menu .menu-items .menu-item.menu-item-two-links{
    display: flex;
    width: 100%;
   flex-direction: row;
    flex-wrap: nowrap;
    color: white;
    padding:2px 0;
    font-size: 14px;
}

#side-menu .menu-items .menu-item a{
    color: white;
    text-align: right;
}

#side-menu .menu-items .menu-item a.one{
    flex: 3;
    padding: 5px 10px 5px 4px;  border-radius: 0 0.25rem  .25rem 0;
}
#side-menu .menu-items .menu-item a.two{
    color: white;
    text-align: left;
    padding: 5px 10px 5px 4px;
    border-radius: .25rem 0 0 .25rem;
}
#side-menu .menu-items .menu-item a.two .icon{color: #fff000}
#side-menu .menu-items .menu-item a.two:hover .icon{color: #fffc3c
}
#side-menu .menu-items .menu-item a:hover{text-decoration: none}

#side-menu .menu-items .menu-item .menu-item-icon{
    float: right;
    flex: 1;
}

#side-menu .menu-items .menu-item .menu-item-title{
    padding-right: 10px;
    flex: 4;
}

#side-menu .menu-items .menu-item:hover .menu-item-icon,#side-menu .menu-items .menu-item:hover a{
    background-color: #7c00b5;
}


.menu-close{
    position: absolute;
    top: 5px;
    left: 5px;
}
