.wrapper{
    background-color: #f2f2f4;min-height: 100vh;
}
.content-wrapper {
    transition: margin-right .3s ease-in-out;
    margin-right: 250px;
    padding: 0 20px;
}

.main-header{
    transition: margin-right .3s ease-in-out;
 width: 100%;
    padding: 0;
}

.main-sidebar{
    height: inherit;
    position: absolute;
    top: 0;
    width: 250px;
    z-index: 100;
    overflow: hidden;
}

@media (max-width: 992px){

    .content-wrapper,.main-header {
        margin-right: 0;
        padding: 0;
    }

    .main-sidebar.open-menu{
        margin-right: 0;
    }

    .main-sidebar.close-menu{
        margin-right: -250px;
    }

    table {

        overflow-x: auto;

    }



}




.nav-sidebar {
    position: relative;
}

.flex-column {
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}

.nav {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-sidebar>.nav-item {
    margin-bottom: 0;
}



input{
    text-align: right;
}



