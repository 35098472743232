
#transfer-form .form-control {
    display:initial !important;
}

.cheque-form{
    direction: rtl;
    text-align: right;
}


.form-row{
    display: flex;
}

textarea,select{
    border-radius: 8px;
    background-color: white;
    border: none;
    line-height: 14px;
    width: 80%;
}

input.silver,textarea.silver,select.silver{
    background-color: #e8e8e8;
    border: 1px solid #e8e8e8;
}

.form-group .form-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.form-group .row{
    padding: 10px 5px;
}

.cheque-form .card{
    width: 100%;
    margin: 5px;
    padding: 5px 10px;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)

}



.card.red{
    background-color: #eb5f8c;
}

.card.blue{
    background-color:#67c7ea;
}

.col-item{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.col-item label{
    display: block;
    min-width: 100px;
    font-size: 14px;
    font-weight: bold;
}

.form-control{
    border-radius: 8px !important;
     
}




select {

    /* styling */
    background-color: white;
    border: none;
    border-radius: 8px;
    display: inline-block;
    font: inherit;
    line-height: 1.2em;

    /* reset */

    margin: 0;
}

.col-footer{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
}

.col-footer .buttons{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.col-footer .delivery{
    position: absolute;
    left: 20px;
    width: 200px;
}

.form-check-label{
    padding-right: 20px;
}

.add-button{
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    font-size: 14px;
    background-color: #2d62ed;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 2px;
    border: none;
    border-radius: 8px;
    z-index: 100;
}

.remove-button{
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    font-size: 14px;
    background-color: red;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 2px;
    border: none;
    border-radius: 8px;
    z-index: 100;
}

.add-button span, .remove-button span {
    z-index: 99;
}



