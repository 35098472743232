.btn{
    margin: 0 auto;
    font-size: 14px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    max-width: 130px;

    border: none;;padding: 6px 10px;
}
.login-form .btn.btn-primary{
    margin:20px auto 10px;
    font-size: 14px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    max-width: 100%;

    border: none;;padding: 6px 10px;
    background: #7d1fcc;
}

.btn.green{
    background-color: #01bf41;
    color: white;
}
.btn.red{
    background-color: #eb5f8c;
    color: white;
}
.btn.blue{
    background-color: #2d62ed;
    color: white;
}

.btn span{
    margin-left: 10px;
}




